import * as BUI from "@thatopen/ui";
import * as OBC from "@thatopen/components";
import * as UTILS from "../../Utils/utils";

// Function to generate the fileTreeHTML from fileTree
// fileTree client > locals > projects > files

export default (components: OBC.Components, world: OBC.World, fileTree: any) => {

    // Set up IfcLoader
    const ifcLoader = components.get(OBC.IfcLoader);
    ifcLoader.setup();

    // Queue to hold the loading tasks
    const loadQueue: Array<{ fileId: string, fileName: string, button: HTMLElement }> = [];
    let isProcessingQueue = false;

    // Load the IFC file by id
    const loadIfcById = async (fileId: string, fileName: string) => {
        console.log(`Iniciando carregamento do arquivo: ${fileName}`);
        try {
            const response = await fetch(`/api/file/${fileId}`);
            if (!response.ok) throw new Error(`Failed to fetch file: ${response.status}`);
            const buffer = new Uint8Array(await response.arrayBuffer());
            const model = await ifcLoader.load(buffer);
            model.name = fileName;
            world.scene.three.add(model);
            console.log(`Arquivo carregado com sucesso: ${fileName}`);
        } catch (error) {
            console.error(`Erro ao carregar o modelo IFC: ${fileName}`, error);
            throw error;
        } finally {
            console.log(`Carregamento concluído: ${fileName}`);
        }
    };

    const processQueue = async () => {
        if (isProcessingQueue) {
            console.log("Fila já está sendo processada.");
            return; // Prevent multiple queue processing
        }
        isProcessingQueue = true;
        console.log("Iniciando o processamento da fila...");

        while (loadQueue.length > 0) {
            const { fileId, fileName, button } = loadQueue.shift()!;
            console.log(`Processando arquivo da fila: ${fileName}`);
            
            // Set initial loading state and disable button
            button.setAttribute("loading", "true");
            button.removeAttribute("label");

            try {
                // Load IFC file and update button state on success
                await loadIfcById(fileId, fileName);
                button.setAttribute("label", "Carregado");
                button.removeAttribute("loading");
                button.setAttribute("disabled", "true");
                console.log(`Arquivo carregado com sucesso, desativando botão: ${fileName}`);
                setTimeout(() => {
                    button.removeAttribute("disabled");
                }, 60000);
            } catch (error) {
                // Handle errors gracefully and provide informative feedback
                console.error(`Erro ao carregar o arquivo IFC: ${fileName}`, error);
                button.setAttribute("label", "Erro");
                button.removeAttribute("loading");

                // Re-enable button after a short delay to allow user retry
                setTimeout(() => {
                    button.setAttribute("label", "Carregar");
                    button.removeAttribute("disabled");
                }, 3000);
            }
        }

        isProcessingQueue = false;
        console.log("Fila processada completamente.");
    };

    const handleClick = (event: MouseEvent, fileId: string, fileName: string) => {
        const button = event.currentTarget as HTMLElement;
        console.log(`Botão clicado para carregar o arquivo: ${fileName}`);

        // Add task to queue
        loadQueue.push({ fileId, fileName, button });
        console.log(`Arquivo adicionado à fila: ${fileName}`);
        
        // Start processing the queue if not already processing
        processQueue();
    };

    const generateFilesHTML = (files: any[]) => {
        files.sort((a, b) => a.name.localeCompare(b.name)); // Sort files alphabetically
        return files.map(file => BUI.html`
            <div style="display: grid; grid-template-columns: 1fr auto; align-items: center; background-color: rgba(128, 128, 128, 0.05); width: 100%;">
                <bim-label style="white-space: normal;" icon="mage:box-3d">${file.name}</bim-label>
                <bim-button style="width: 6rem;" data-column-index="1" label="Carregar" @click=${(e: MouseEvent) => handleClick(e, file.id, file.name)}></bim-button>
            </div>
        `);
    };

    const generateProjectsHTML = (projects: any[]) => {
        projects.sort((a, b) => a.name.localeCompare(b.name)); // Sort projects alphabetically
        return projects.map(project => BUI.html`
            <bim-panel-section collapsed label="${project.name}" icon="ic:outline-auto-awesome-mosaic" style="background-color: rgba(128, 128, 128, 0.05);">
                ${generateFilesHTML(project.files)}
            </bim-panel-section>
        `);
    };

    const generateLocalsHTML = (locals: any[]) => {
        locals.sort((a, b) => a.name.localeCompare(b.name)); // Sort locals alphabetically
        return locals.map(local => BUI.html`
            <bim-panel-section collapsed label="${local.name}" icon="bi:buildings">
                ${generateProjectsHTML(local.projects)}
            </bim-panel-section>
        `);
    };

    return BUI.Component.create<BUI.Panel>(() => {
        return BUI.html`
            <bim-panel>
                <bim-panel-section fixed label="${UTILS.capitalize(fileTree.name)}" icon="uiw:global">
                <bim-label style="white-space: normal;">Abaixo estão os projetos para serem carregados no visualizador 3D.</bim-label>
                </bim-panel-section>
                ${generateLocalsHTML(fileTree.locals)}
            </bim-panel>  
        `;
    });

};
