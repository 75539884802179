import * as OBC from "@thatopen/components";
import * as OBF from "@thatopen/components-front";
import * as BUI from "@thatopen/ui";

export default (components: OBC.Components, app: BUI.Grid, world: OBC.World) => {
  const dimensions = components.get(OBF.LengthMeasurement);
  dimensions.world = world;
  dimensions.snapDistance = 1;

  const areaDims = components.get(OBF.AreaMeasurement);
  areaDims.world = world;
  
  const onMeasureDistance = (e: Event) => {
    const button = e.target as BUI.Button;
    
    if (areaDims.enabled) {
      areaDims.enabled = false;
      const areaButton = document.querySelector('bim-button[label="Área"]') as BUI.Button;
      if (areaButton) areaButton.active = false;
    }
    
    dimensions.enabled = !dimensions.enabled;
    button.active = dimensions.enabled;

    components.get(OBF.Highlighter).enabled = !dimensions.enabled;

    app.ondblclick = () => dimensions.create();

    window.onkeydown = (event) => {
      if (event.code == "Escape" || event.code == "Enter") {
        dimensions.endCreation();
        dimensions.cancelCreation();
      }
    }

    window.onkeydown = (event) => {
      if (event.code == "Delete" || event.code == "Backspace") {
        dimensions.delete();
      }
    }
  }

  const onMeasureArea = (e: Event) => {
    const button = e.target as BUI.Button;
    
    if (dimensions.enabled) {
      dimensions.enabled = false;
      const distanceButton = document.querySelector('bim-button[label="Distância"]') as BUI.Button;
      if (distanceButton) distanceButton.active = false;
    }
    
    areaDims.enabled = !areaDims.enabled;
    button.active = areaDims.enabled;

    components.get(OBF.Highlighter).enabled = !areaDims.enabled;

    app.ondblclick = () => areaDims.create();
    app.oncontextmenu = () => areaDims.endCreation();

    window.onkeydown = (event) => {
      if (event.code == "Escape" || event.code == "Enter") {
        areaDims.endCreation();
        areaDims.cancelCreation();
      }
    }

    window.onkeydown = (event) => {
      if (event.code == "Delete" || event.code == "Backspace") {
        areaDims.deleteAll();
      }
    }
  }

  const onDeleteAll = () => {
    dimensions.deleteAll();
    areaDims.deleteAll();
  }

  

  return BUI.Component.create<BUI.PanelSection>(() => {
    return BUI.html`
      <bim-toolbar-section label="Medir" icon="la:pencil-ruler">
        <bim-button @click=${onMeasureDistance} label="Distância" .active=${dimensions.enabled} icon="hugeicons:tape-measure" tooltip-title="Distância" tooltip-text="Use clique duplo para medir a distância entre dois pontos."></bim-button>
        <bim-button @click=${onMeasureArea} label="Área" .active=${areaDims.enabled} icon="ic:baseline-square-foot" tooltip-title="Área" tooltip-text="Use duplo clique para medir a área dentro de pelo menos três pontos."></bim-button>
        <bim-button @click="${onDeleteAll}" label="Limpar" icon="material-symbols-light:delete-outline" tooltip-title="Limpar" tooltip-text="Limpa todas as medidas.">       
      </bim-toolbar-section>
    `;
  });
}
