import * as BUI from "@thatopen/ui"
import { updateFiles, userdata } from "../../Utils/apiCalls"; // Import function to verify JWT token

export default BUI.Component.create<BUI.Panel>(() => {
  return BUI.html`
    <bim-panel>
      <bim-panel-section fixed label="Sobre esta Aplicação" icon="simple-line-icons:check">
        <bim-label style="white-space: normal;">Esta é uma aplicação desenvolvida pela Technoproj para que você possa acessar os Modelos 3D dos projetos desenvolvidos por nós.</bim-label>
      </bim-panel-section>
      <bim-panel-section fixed label="Visualise os Modelos 3D" icon="mage:box-3d">
        <bim-label style="white-space: normal;">Na aba "Projetos" você pode carregar os Modelos 3D. Após carregar os medelos desejados você pode vê-los na aba "Modelos", onde é possível deletá-los.</bim-label>
      </bim-panel-section>
      <bim-panel-section fixed label="Ajuste a aplicação conforme sua necessidade" icon="oui:gear">
        <bim-label style="white-space: normal;">Para ajustar a visualização 3D vá na aba "Opções", nela você pode configurar as opções do visulizados caso algo não esteja bem configurado para seu navegador.</bim-label>
      </bim-panel-section>
      <bim-panel-section fixed label="Está com problemas?" icon="streamline:help-chat-2">
        <bim-label style="white-space: normal;">A Technoproj é uma empresa de engenharia especializada em projetos de engenharia. Para saber mais sobre a Technoproj acesse o site.</bim-label>
        <bim-button @click=${()=> open("https://www.technoproj.com.br")} label="Visite o Site" icon="simple-line-icons:globe"></bim-button>
      </bim-panel-section>  
      ${userdata.userClient === "technoproj" ? BUI.html`
      <bim-panel-section fixed label="Atualizar banco de dados de projetos" icon="grommet-icons:update">
        <bim-label style="white-space: normal;">Clique no botão abaixo para atualizar a lista de projetos. A página será atualizada após o banco de dados ser atualizado.</bim-label>
        <bim-button @click="${updateFiles}" label="Atualizar Projetos" icon="grommet-icons:update"></bim-button>
      </bim-panel-section>
      ` : ''}
    </bim-panel>
  `
})