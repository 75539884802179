export async function verifyJWT(token: string) {
  try {
    const response = await fetch('/api/verify', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ token })
    });

    const userdata = await response.json();

    if (userdata.userClient) {
      // return user data
      return {
        userDisplayName: userdata.userDisplayName,
        userUid: userdata.userUid,
        userEmailDomain: userdata.userEmailDomain,
        userEmail: userdata.userEmail,
        userClient: userdata.userClient
      };
    } else {
      // return error
      return { error: userdata.error };
    }
  } catch (error) {
    console.error('Error verifying JWT:', error);
    // return error
    return { error: 'jwt.error' };
  }
}

export async function getFileTree(userClient: string) {
  try {
    const response = await fetch(`/api/client/${userClient}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });

    const fileTree = await response.json();

    // console
    console.log('User client:', userClient);
    console.log('File tree:', fileTree);

    return fileTree;
  } catch (error) {
    console.error('Error getting file tree:', error);
    return { error: 'filetree.error' };
  }
}

// function to update files /rescan
export async function updateFiles() {
  try {
    const response = await fetch('/api/rescan', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });

    // console
    console.log('Update:', response);

    // update the page on browser
    window.location.reload();

    return response;
  } catch (error) {
    console.error('Error updating files:', error);
    return { error: 'update.error' };
  }
}

export const userdata = {
  userClient: "technoproj", // ou qualquer outra lógica para determinar o cliente do usuário
  // outros campos conforme necessário
};